export const THREE_CREATE_METHOD_DATA = [
    {
        image: 'https://res.cloudinary.com/dmcrkqndq/image/upload/v1624869452/clip-1642_1_mg46ia.svg',
        imageCaption: 'Step 1',
        imageClassName: 'border rounded',
        header: 'Step 1',
        title: 'Connect',
        cardBackground:'px-0',
        description: 'We\'ll connect with you to understand your vision, purpose, and audience – then define what success looks like for you.',
    },
    {
        image: 'https://res.cloudinary.com/dmcrkqndq/image/upload/v1624869453/clip-web-design_hh6hn0.svg',
        imageCaption: 'Step 2',
        imageClassName: 'border rounded',
        header: 'Step 2',
        title: 'Brief',
        cardBackground:'px-0',
        description: 'Whether you have a clear idea or don\'t know how to start, our team will develop a brief for your course – outlining the platform of choice, learning objectives and a course outline.'
    },
    {
        image: 'https://res.cloudinary.com/dmcrkqndq/image/upload/v1624869453/clip-1675_coztfq.svg',
        imageCaption: 'Step 3',
        imageClassName: 'border rounded',
        header: 'Step 3',
        title: 'Build',
        cardBackground:'px-0',
        description: 'After sign off, we start building your course. Then, every couple of days, you\'ll get an update and be able to start testing your course.'
    },
    {
        image: 'https://res.cloudinary.com/dmcrkqndq/image/upload/v1624869471/clip-1448_d4sbtb.svg',
        imageCaption: 'Step 4',
        imageClassName: 'border rounded',
        header: 'Step 4',
        title: 'Hand-over',
        cardBackground:'px-0',
        description: 'As we near the course completion, we\'ll begin handover – including training, connecting it to your website and tips to commercialize the content.'
    }
]

export const FOUR_WORDS_PROCESS_PAGE_DATA = [
    {
        super: 'Step by step',
        spacing: 'pt-3 pb-0',
        animation: false,
        title: 'Getting your course created',
    },
    {
        super: 'Step 1',
        spacing: 'pt-0 pb-3',
        title: 'Connect',
        content: [
            'This will take approximately 20 - 30 minutes.',
            'We want to understand your vision, purpose and audience for your course. We will look to define what success looks like for you.',
            'After the call, we\'ll send a summary of our conversation, and start developing a brief before sign off.'

        ]
    },
    {
        background: 'bg-cream',
        super: 'Step 2',
        spacing: 'pt-3 pb-3',
        title: 'Brief',
        content: [
            'Allow for a day or two – normally within 24 hours.',
            'We\'ll catch up to outline the details – including the platform of choice, learning objectives, course outlines and resource allocated.',
            'Our learning consultants will also show you the different parts of your course, what features are possible and outline any ideas or concepts you might want to include.',
            'If you have any changes or want a bigger course, our team can restructure the brief with comprehensive timelines and pricing.'
        ]
    },
    {
        super: 'Step 3',
        spacing: 'pt-3 pb-3',
        title: 'Build',
        content: [
            'Once the brief is signed off, we will start building your course. We will send you updates every couple of days and you will be able to start testing your course right away. <br/>',
            '<strong>FAQS</strong><br/>',
        ],
        listContent: [
            '<strong>Do I need to be involved with testing?</strong><br/><p>Yes. You need to provide feedback on the course development. This will take about five minutes or so every few days. This will ensure that we are building exactly what you want and that there are no surprises at the end.</p>',
            '<strong>Do I need to send through any resources?</strong><br/><p>If you have any resources, that would be great. Even if you don\'t, we can go find the information and validate it with you before building the course.</p>',
            '<strong>How will you learn my skill and then make a course out of it?</strong><br/><p>We tend to engage with subject matter experts. Even if you send us some links for us to look at, we can get started with building the content. We are also experts in learning with much of the team holding a masters or PhD. </p>',
            '<strong>What platform will you use?</strong><br/><p>This will be decided by considering your requirements. We are comfortable with most tools such as Thinkific, Teachable, Wikis etc.</p>'
        ]
    },
    {
        background: 'bg-cream',        
        super: 'Step 4',
        spacing: 'pt-3 pb-3',
        title: 'Training & Hand-over',
        content: [
            'As we near the completion of the course, we will walk you through how to commercialize your content, connect Paypal and Stripe and create discount codes.',
            'We can also help you connect your course to a landing page.',
            '<strong>FAQS</strong><br/>',
        ],
        listContent:[
            '<strong>How many hours of training do you provide?</strong><br/><p>We provide a one hour training session, and then one hour of additional support. This is more than enough time to learn online courseware software.</p>',
            '<strong>If I would like some minor work done after hand-over, can I use your services?</strong><br/><p>Yes. We have a standard hourly rate if you would like us to jump in and continue to help you.</p>',
            '<strong>What if I am not happy with the course?</strong><p>We will be asking for feedback every few days. During the hand-over, we will also go through the course and make sure that you are happy with each piece of content. We will rectify anything that you do not like within reason.</p>',
            '<strong>Do you have any ongoing fees?</strong><p>No. We will pass on platform fees to you. We personally do not have any ongoing fees after the hand-over.</p>'
        ],
    }

]